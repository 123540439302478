
import { defineComponent, onMounted, ref ,Ref,PropType,watch} from 'vue';
import { ContentLoader } from 'vue-content-loader';

import ApexChart  from "apexcharts";


export default defineComponent({
    props: {
      pacotes: Object,
      showGraficos: Boolean, 
      loadingCards:  Boolean
    },    
  components: { ContentLoader },
    setup(props){
      const showGrafico = ref(true)
      const loading = ref(true)
      const series = ref()
      const labels = ref()
      const colors = ref(['var(--cor_indicadores_1)', 'var(--cor_indicadores_2)', 'var(--cor_indicadores_3)', 'var(--cor_indicadores_4)',"#654889"])
      const pacotesData = ref({})
      let chartInstance;
      function geraOptions(labelsRef, refColors, isMobile = false) {
      const newOptions = {
        dataLabels: {
          enabled: true,
        },
        chart: {
          width: '100%',
          height: 290,
          type: 'donut',
          options: "chart",
          series: [],
        },
        stroke: {
          width: 0
        },
        legend: {
          horizontalAlign: 'left', 
          position: isMobile ? 'bottom' : 'right'
        },
        labels: labelsRef,
        colors: refColors,
        fill: {
          colors: refColors,
        },
      }

      return newOptions;
    }


    watch(() => props.showGraficos,() => {
        showGrafico.value = props.showGraficos
      })

    watch(() => props.loadingCards,() => {
      loading.value = props.loadingCards
    })

    watch(() => props.pacotes, () => {
      
      if(props.pacotes){
        pacotesData.value = props.pacotes
        series.value = Object.values(pacotesData.value).map(value => Number(value))
        labels.value = Object.keys(pacotesData.value)

        setTimeout(()=> {
        chartInstance.updateSeries(series.value);
        chartInstance.updateOptions(geraOptions(labels.value, colors.value));
      },500)
      }
    },{deep: true})

    onMounted(()=> {

       const options = geraOptions([], colors.value);
          chartInstance = new ApexChart(document.querySelector("#grafico-pacotes"), {
            ...options,
            series: [],
          });

      chartInstance.render();
    })

    return {
        chartInstance,
        showGrafico,
        loading,
        pacotesData
    }
    }
})
