import moment from 'moment';
import { ref } from 'vue';

const globalDate = ref('');
const dateInitial30Days = ref()

function formateDate(dataInicial, dataFinal){
    dataInicial = moment(dataInicial).startOf('day').format("DD/MM/YYYY");
    dataFinal = moment(dataFinal).endOf('day').format("DD/MM/YYYY");

    const filters = `${dataInicial} - ${dataFinal}`;

    return filters;
}; 

const generateDate30Days = () => {
// Importar a biblioteca moment.js
const moment = require('moment');

// Obter a data atual e subtrair 30 dias
const dataMenos30Dias = moment().subtract(30, 'days');

// Formatar as datas como YYYY-MM-DDTHH:mm:ss.sssZ
const data1 = dataMenos30Dias.toISOString();
const data2 = moment().toISOString();

// Criar o array com as duas datas
const arrayDatas = [data1, data2];

dateInitial30Days.value = arrayDatas

globalDate.value = formateDate(arrayDatas[0],arrayDatas[1])

}

export  {generateDate30Days,globalDate,dateInitial30Days,formateDate}