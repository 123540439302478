
import {defineComponent,onMounted, reactive,ref,Ref} from 'vue'
import { listaOs , paginate } from '@/services/ListaOS'
import CardErroSucess from './components/CardErroSucess.vue'
import CardPacotes from './components/CardPacotes.vue'
import moment from 'moment'
import { ContentLoader } from 'vue-content-loader'
import useEmitter from '@/composables/Emmiter'
import {globalDate,generateDate30Days} from './composables/dateInitial'
import ModalOS from './components/ModalOS.vue'
import useAlert from "@/composables/Alert";
import router from '@/router'

interface ListaDeOS {
    abertadms : string,
    cliente: string,
    codConcessionaria: string,
    codConsultor: string,
    codOS: string,
    codPacote: string,
    concessionariaNome: string,
    consultorNome: string,
    data: string,
    email: string,
    modelo: string,
    pacote: string,
    placa: string,
    retornoIntegracao: string,
    revisao: string,
    telefone:  string
}
interface Data {
    current_page: number,
    data: ListaDeOS[],
    first_page_url: string,
    from: number,
    last_page: number,
    last_page_url: string,
    links: Array<[]>,
    next_page_url : string | null,
    path: string,
    per_page: number,
    prev_page_url: string | null,
    to: number,
    total:number

}


interface responseOS {
    data: Data,
    iTotalDisplayRecords: number,
    iTotalRecords: number,
    lastDate: string,
    totalErro:string | string,
    totalSucesso: string,
    pacotes: string[]
}

export default defineComponent({
    name: "oficina-digital-reprovados",
    components: { 
        ContentLoader,
        CardErroSucess,
        CardPacotes,
        ModalOS
     },
    setup() {
        const emmite = useEmitter()
        const animate = ref(true)
        const codOSselected = ref()
        const consultorNome = ref()
        const codConsultor = ref()
        const showGraficos = ref(true) // condição se os card vai aparecer
        const loadingCards = ref(true) // loading dos cards que contem os graficos
        const paginateSelectDisplay = ref() // pagina selecionada 
        const selectConcessionaria = ref(localStorage.getItem("filtroConcessionaria"));
        const listOS: Ref<responseOS | undefined> = ref();
        const { showTimeAlert } = useAlert();
        const currentPage = ref(1)
        const filterOS = reactive({          
            date: '',
            status: null,
            pacote: null,
            codConsultor: null,
        });
        const paginationConfig = reactive({
            current_page: 1,            
            last_page: 7,
            per_page: 50,
            total: 500,
            to: 0,
            data: []
        })
        onMounted(async () => {
            generateDate30Days()
            //  filterOS.fDate = '03/03/2021 - 12/04/2023'
            filterOS.date = globalDate.value
             loadingCards.value = true
            await getOS(filterOS)
            loadingCards.value = false                      
        });

        const formatDate = (date) => {
            return moment(date).format("DD/MM/YYYY HH:mm");
        };
        

        const pagination = async (pageSelected) => {
            currentPage.value = pageSelected
            animate.value = true
            window.scroll({
              top: 0,
              behavior: 'smooth'
            })
            try{
                listOS.value = await paginate(`ordem_servico_v3/${selectConcessionaria.value}?page=${pageSelected}`,filterOS)  
            }catch (error) {
             showTimeAlert("Não foi possível completar a solicitação", "error");         
             router.push({name: 'ordem-servico-gerenciar'})   
            }

            animate.value = false
            showGraficos.value = true               
            paginateSelectDisplay.value =  currentPage.value            
        };

      async function getOS(payload){
        animate.value = true      
        try {
            listOS.value = await listaOs(selectConcessionaria.value, payload);            
        } catch (error) {
            showTimeAlert("Não foi possível completar a solicitação", "error");  
            router.push({name: 'ordem-servico-gerenciar'})           
        }
        animate.value = false
        }        

        emmite.on('filter-OS',async (data) => {
            currentPage.value = 1
            Object.assign(filterOS,data.filterOs)      
            selectConcessionaria.value = data.codConcesionaria
            loadingCards.value = true
            showGraficos.value = !loadingCards.value
            await getOS(filterOS)   
            loadingCards.value = false
            showGraficos.value = true

        })

        emmite.on('gerarXlsxListaOS', () => {
            emmite.emit("retornoXlsxListaOS",filterOS);
        })

        const OSselected = (codOS,consultorNomeOS, codConsultorOS) => {           
            codOSselected.value = codOS     
            consultorNome.value = consultorNomeOS
            codConsultor.value =   codConsultorOS              
        }

        return {
            listOS,
            formatDate,
            animate,
            paginationConfig,
            pagination,
            filterOS,
            showGraficos,
            loadingCards,
            OSselected,
            codOSselected,
            paginateSelectDisplay,
            consultorNome,
            currentPage,
            codConsultor,
        };
    },
   
})
